.main-logo {
  width: 100%;

  img {
    max-height: 40px;
  }
}

.auth-wrapper,
.terms-logo,
.misc-wrapper {
  .main-logo {
    img {
      max-height: 80px;
    }
  }
}
