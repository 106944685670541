.printSpinner {
  margin-top: 3px;
  margin-left: -40px;
  position: absolute;
  display: inline-block;
  width: 25px;
  height: 25px;
	border: 2px solid $primary;
  border-radius: 50%;
  animation: spin 0.75s infinite linear;
}

.printSpinner::before, .printSpinner::after {
  left: -2px;
  top: -2px;
  display: none;
  position: absolute;
  content: '';
  width: inherit;
  height: inherit;
  border: inherit;
  border-radius: inherit;
}

.printSpinner, .printSpinner::before, .printSpinner::after {
  display: inline-block;
  border-color: transparent;
  border-top-color: $primary;
  animation-duration: 1.2s;
}
.printSpinner::before {
  transform: rotate(120deg);
}
.printSpinner::after {
  transform: rotate(240deg);
}

/* Keyframes for the animation */
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}