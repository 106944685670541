.profile-info {
  position: relative;
}

.profiles-mobile-list {
  .profile-card {
    position: relative;

    .profile-btn {
      position: absolute;
      top: 4px;
      right: 4px;
    }
  }
}

.profile-extended-list {
  & .card-body {
    max-height: 340px;
    overflow-y: auto;
  }
}