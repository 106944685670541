@import 'src/@core/scss/base/components';

.header-navbar-container {
  .dropdown-menu[data-popper-placement]:not([data-popper-placement^='top-']) {
    top: 3.5rem !important;
  }

  .scrollbar-container {
    max-height: 25rem;
  }

  .logo-bar {
    .brand-label {
      font-size: 1rem;
    }
  }
}

.vertical-layout .header-navbar-container .dropdown-menu {
  min-width: 100%;
}
.horizontal-layout .header-navbar-container .logo-bar .brand-label {
  font-size: 1.45rem;
}