.printModal {
  font-family:sans-serif;
  display:flex;
  text-align:center;
  font-weight:300;
  font-size:30px;
  left:0;
  top:0;
  position:absolute;
  color: $primary;
  width: 100%;
  height: 100%;
  background-color: $bg-color;
}

/*
|--------------------------------------------------------------------------
| Close Button
|--------------------------------------------------------------------------
*/
.printClose {
  position:absolute;
  right:10px;
  top:10px;
}

.printClose:before {
  content: "\00D7";
  font-family: "Helvetica Neue", sans-serif;
  font-weight: 100;
  line-height: 1px;
  padding-top: 0.5em;
  display: block;
  font-size: 2em;
  text-indent: 1px;
  overflow: hidden;
  height: 1.25em;
  width: 1.25em;
  text-align: center;
  cursor: pointer;
}