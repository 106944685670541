@import '../../../scss/base/bootstrap-extended/include'; // Bootstrap includes
@import '../../../scss/base/components/include'; // Components includes

.main-menu-vertical {
  .link-title {
    max-width: 80px;
  }

  .link {
    &:not(:last-child) {
      margin-right: 0.8rem;
    }
  }
}

@include media-breakpoint-down(md) {
  .link-title {
    font-size: 12px;
  }
}
