@import './base/bootstrap.scss';
@import './base/bootstrap-extended.scss';
@import './base/colors.scss';
@import './base/components.scss';

// Themes
@import './base/themes/dark-layout.scss';
@import './base/themes/bordered-layout.scss';
@import './base/themes/semi-dark-layout.scss';
// @import './base/custom-rtl.scss';

// React Specific
@import 'react/index';

*::-webkit-scrollbar {
  width: 0.75em;
}

*::-webkit-scrollbar-track {
  -webkit-box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
}

*::-webkit-scrollbar-thumb {
  background-color: #27b6d7;
  outline: 1px solid #283046;
}

.ml-auto {
  margin-left: auto !important;
}

.mr-auto {
  margin-right: auto !important;
}

.webkit-input {
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}
.wrapper-vertical {
  min-height: 100%;
  position: relative;
  overflow: hidden;
}

.web-kit-sticky {
  transform: translate3d(0,0,0);
}

.vertical-content {
  margin-bottom: 70px;
}