@import '../../@core/scss/base/bootstrap-extended/variables';

.requiredFormLabelAsterisk {
  color: $danger;
  font-size: 1.2em;
  margin-left: 0.4rem;
}

.pointer-events-all {
  pointer-events: all;
}